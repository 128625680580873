import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PurchaseCopy from '../components/PurchaseCopy';

import './the-advancing-guitarist.css'

const TheBookPage = () => (
  <Layout>
    <SEO title="The Advancing Guitarist, by Mick Goodrick" />
    <h2>The Advancing Guitarist, by Mick Goodrick</h2>
    <div className="text-page">

      <PurchaseCopy>
        <b>The Advancing Guitarist</b> is a guitar-focused applied music-theory 
        book by jazz guitarist Mick Goodrick. The book proposes that the first stringed 
        instrument may have been a single-string "unitar", and that
        perhaps we should seek to understand how the first <em>unitarists</em> played their 
        instruments to inform our own modern guitar playing!
      </PurchaseCopy>

      <p>
        The author, Mick Goodrick, is a renowned veteran jazz guitarist.
        <iframe 
          title="Live performance with Mick Goodrick, John Scofield, Emily Remler, and Jon Abercrombie "
          width="560" height="315" 
          src="https://www.youtube.com/embed/4Gwjs-tf3MI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </p>

      <p>
        <b>The Advancing Guitarist</b> encourages aspiring guitarists to take 
        measure of the fretboard and account for all its possibilities, including 
        scales, modes, triads, intervals, chords and more. The 
        book also suggests some vamps to aid in your improvisation. A few of 
        these vamps are available on YouTube, if you care to search them out. 
        The following is an example of the F Lydian vamp from the book:
        <iframe 
          title="F Lydian guitar vamp"
          width="560" height="315" 
          src="https://www.youtube.com/embed/lIV-nuvYdec" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </p>

      <p>
        <Link to="/">Back to home</Link>
      </p>
    </div>
  </Layout>
)

export default TheBookPage
